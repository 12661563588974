import {Subject} from "rxjs";
import {Fetch} from "./Fetch";

export type Recipe = {
    id: number,
    name: string,
    ingredients: (Ingredient & {minamount: number|null, maxamount: number|null, unit: string|null})[],
    traits: bigint
}

export type TempRecipe = {
    id?: number,
    name: string,
    ingredients: (Ingredient & {amount: string | null})[],
}

export type Ingredient = {
    id: number,
    name: string,
    traits: bigint
}

export const BASEURL = ''

class DataClass {

    private recipes: Recipe[] = [];
    private ingredients: Ingredient[] = [];

    private recipesSubject = new Subject<Recipe[]>()
    private ingredientsSubject = new Subject<Ingredient[]>();

    public observeRecipes = () => this.recipesSubject.asObservable();
    public observeIngredients = () => this.ingredientsSubject.asObservable();

    private async fetchRecipes() {
        const response = await Fetch.get('/api/recipe');
        this.recipes = await response.json();
        this.recipesSubject.next(this.recipes);
    }

    private async fetchIngredients() {
        const response = await Fetch.get('/api/ingredient');
        this.ingredients = await response.json();
        this.ingredientsSubject.next(this.ingredients);
    }

    public async init() {
        await Promise.all([this.fetchIngredients(), this.fetchRecipes()]);
    }

    public getRecipes() {
        return this.recipes;
    }

    public async addRecipe(recipe: TempRecipe) {
        const response = await Fetch.post('/api/recipe', recipe);
        const {recipes, ingredients} = await response.json();
        this.recipes = recipes;
        this.recipesSubject.next(this.recipes);
        this.ingredients = ingredients;
        this.ingredientsSubject.next(this.ingredients);
    }

    public getIngredients() {
        return this.ingredients;
    }
}

export const Data = new DataClass();
