import {
    Box,
    FormControl,
    Heading,
    TextInput
} from "@primer/react";
import {PencilIcon, SearchIcon} from '@primer/octicons-react';
import React from "react";
import {Data, Ingredient} from "./Data";
import {Dialog} from "@primer/react/drafts";

export function Products() {
    const [ingredients, setIngredients] = React.useState(Data.getIngredients());
    Data.observeIngredients().subscribe((x) => setIngredients(x));

    const [filterVal, setFilterVal] = React.useState('');

    return (
        <>
            <Box sx={{width: '100%', display: 'flex', alignItems: 'center', px: 2, pt: 2, bg: 'canvas.default', justifyContent: 'end'}}>
                <Box sx={{py: 2, pr: 3}}>
                    <TextInput sx={{minWidth: 'min(400px, 30vw)'}}
                               value={filterVal}
                               onChange={(e) => setFilterVal(e.currentTarget.value)}
                               leadingVisual={SearchIcon}
                               aria-label="Suchen"
                               name="name"
                               placeholder="Suchen"
                               autoComplete="off"
                    />
                </Box>
            </Box>
            <Box sx={{flexGrow: 1, px: 4, py: 2}} bg="canvas.default">
                <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>{
                    ingredients
                        .filter(ingredient => ingredient.name.toLowerCase().includes(filterVal.toLowerCase()))
                        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                        .map(ingredient => (
                            <IngredientElement key={ingredient.id} ingredient={ingredient}></IngredientElement>
                        ))
                }</Box>
            </Box>
        </>
    );
}

function IngredientElement(args: {ingredient: Ingredient}) {
    const {ingredient} = args;
    const [isOpen, setIsOpen] = React.useState(false);

    const nameInput = React.useRef<any>(null);

    const openDialog = React.useCallback(() => setIsOpen(true), [setIsOpen]);
    const closeDialog = React.useCallback(() => setIsOpen(false), [setIsOpen]);

    const onSubmit = async() => {
        closeDialog();
    }

    return (
        <Box sx={{
            borderWidth: 1, borderStyle: 'solid', borderColor: 'border.default', px: 3, py: 2, borderRadius: 2,
            bg: "canvas.subtle", display: "flex", gap: 3, alignItems: "center"
        }}>
            <Heading sx={{fontSize: 16}}>{ingredient.name}</Heading>
            <Box sx={{marginLeft: "auto"}} onClick={() => openDialog()}>
                <PencilIcon size={20}></PencilIcon>
            </Box>
            {isOpen && (
                <Dialog
                    title="Produkt bearbeiten"
                    footerButtons={[{content: 'Nicht speichern', onClick: onSubmit, buttonType: 'primary'}]}
                    onClose={closeDialog}
                >
                    <FormControl required={true} sx={{alignItems: 'unset'}}>
                        <FormControl.Label id="productEditor-nameLabel">Name</FormControl.Label>
                        <TextInput placeholder="" defaultValue={ingredient.name} ref={nameInput}
                                   aria-labelledby="productEditor-nameLabel"></TextInput>
                    </FormControl>
                </Dialog>
            )}
        </Box>
    );
}
