import React, {useRef} from "react";
import {Data, Recipe, TempRecipe} from "./Data";
import {Autocomplete, Box, Button, FormControl, Text, TextInput} from "@primer/react";
import {Form} from "react-router-dom";
import {PlusIcon, TrashIcon} from "@primer/octicons-react";
import {Dialog} from "@primer/react/drafts";

export function recipeToDisplayRecipe(recipe: Recipe): TempRecipe {
    return {
        ingredients: recipe.ingredients.map(i => ({...i, amount: `${i.minamount === i.maxamount ? i.minamount ?? '' : i.minamount + '-' + i.maxamount}${i.unit ?? ''}`})),
        id: recipe.id,
        name: recipe.name
    }
}

export function RecipeEditor(args: {recipe: TempRecipe, close: () => void}) {
    const {recipe, close} = args;

    let nameInput = useRef<any>(null);

    const [allIngredients, setAllIngredients] = React.useState(Data.getIngredients());
    Data.observeIngredients().subscribe(allIngredients => setAllIngredients(allIngredients));

    const [ingredientInput, setIngredientInput] = React.useState('');
    const [localItemsState, setLocalItemsState] = React.useState(recipe.ingredients);

    let split = 0;

    if (/^\d/.test(ingredientInput)) {
        split = ingredientInput.indexOf(' ');
        if (split === -1) {
            split = ingredientInput.length;
        }
    }

    const ingredientAmount = ingredientInput.substring(0, split);
    const filterVal= ingredientInput.substring(split).trim();

    const onItemSelect = items => {
        let item = items[0];

        if (item.id) {
            item = {...allIngredients.find(i => i.id === item.id), amount: item.amount ?? ''}
        } else {
            item = {name: item.text, traits: 0, amount: item.amount ?? ''}
        }

        if (!localItemsState.some(localItem => (localItem.id ?? localItem.name) === (item.id ?? item.name))) {
            setLocalItemsState([...localItemsState, item])
        }

        setIngredientInput('');
    }

    const handleChange = event => {
        setIngredientInput(event.currentTarget.value)
    }

    const handleAmount = (item, event) => {
        setLocalItemsState(localItemsState.map(i =>
            (i.id ?? i.name) === (item.id ?? item.name) ? {...i, amount: event.currentTarget.value} : i));
        if (isValidAmount(event.currentTarget.value)) {
            event.currentTarget.setCustomValidity('');
        } else {
            event.currentTarget.setCustomValidity('No.');
        }
    }

    const isValidAmount = (e) => {
        const regex = /^(\d*(?:[.,]\d+)?)(?:-(\d*(?:[.,]\d+)?))?\s*(\w.*)?$/;
        const result = regex.exec(e);
        if (!result) {
            return false;
        }
        const [, minAmount, , unit] = result;
        if (unit && !minAmount) {
            return false;
        }
        return [undefined, 'kg', 'g', 'l', 'ml'].includes(unit?.toLowerCase());
    }

    const submit = async() => {
        await Data.addRecipe({
            id: recipe.id, // Undefined in a new recipe.
            name: nameInput.current.value,
            ingredients: localItemsState.map(item => ({
                id: item.id, // Undefined in a new ingredient.
                amount: item.amount,
                name: item.id ? undefined : item.name,
                traits: item.id ? undefined : item.traits
            }))
        });
        close();
    }

    return (
        <Dialog
            title="Rezept bearbeiten"
            onClose={close}
            >
            <Box p={3} display="flex" flexDirection="column" alignContent="space-around">
                <Form onSubmit={submit}>
                    <FormControl sx={{alignItems: 'unset'}} required>
                        <FormControl.Label id="recipeEditor-nameLabel">Name</FormControl.Label>
                        <TextInput placeholder="" defaultValue={recipe.name} ref={nameInput}
                                   aria-labelledby="recipeEditor-nameLabel"></TextInput>
                    </FormControl>
                    <FormControl sx={{alignItems: 'unset', mt: 3}}>
                        <FormControl.Label id="autocompleteLabel-addItem">Zutaten</FormControl.Label>
                        <Box sx={{
                            alignItems: 'unset',
                            backgroundColor: "canvas.inset",
                            borderRadius: 6,
                            borderColor: "border.default",
                            borderWidth: 1,
                            borderStyle: 'solid'
                        }}>
                            {localItemsState.map(item =>
                                <Box key={item.id ?? 'name-' + item.name} sx={{
                                    display: 'flex',
                                    gap: 4,
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderBottomStyle: 'solid',
                                    borderColor: "border.default",
                                    borderWidth: 1
                                }} p="1">
                                    <TextInput value={item.amount} className="custom-validity"
                                               onChange={(e) => handleAmount(item, e)} sx={{width: 80}}>
                                    </TextInput>
                                    <Text sx={{flexGrow: 1, color: 'fg.muted'}}>
                                        {item.name}
                                    </Text>
                                    <Box p={1} sx={{color: 'fg.muted'}}
                                         onClick={() => setLocalItemsState(localItemsState.filter(i => i.name !== item.name))}>
                                        <TrashIcon size={20}></TrashIcon>
                                    </Box>
                                </Box>
                            )}
                            <Autocomplete>
                                <Autocomplete.Input onChange={handleChange} width={1} value={ingredientInput}
                                                    leadingVisual={PlusIcon}
                                                    sx={{
                                                        border: 'none',
                                                        borderTopLeftRadius: 0,
                                                        borderTopRightRadius: 0
                                                    }}/>
                                <Autocomplete.Overlay>
                                    <Autocomplete.Menu
                                        addNewItem={
                                            filterVal && !localItemsState.map(localItem => localItem.name).includes(filterVal) && !allIngredients.map(i => i.name).includes(filterVal)
                                                ? {
                                                    text: `'${filterVal}' hinzufügen`,
                                                    handleAddItem: item => {
                                                        onItemSelect([{
                                                            text: filterVal,
                                                            amount: ingredientAmount
                                                        }]);
                                                    },
                                                }
                                                : undefined
                                        }
                                        items={allIngredients
                                            .filter(ingr => !localItemsState.some(usedIng => ingr.id === usedIng.id))
                                            .map(x => ({id: x.id, text: x.name}))
                                        }
                                        filterFn={item => item.text.startsWith(filterVal)}
                                        onSelectedChange={onItemSelect}
                                        selectedItemIds={[]}
                                        aria-labelledby="autocompleteLabel-addItem"
                                    />
                                </Autocomplete.Overlay>
                            </Autocomplete>
                        </Box>
                    </FormControl>
                    <Button variant="primary" sx={{margin: '16px auto 0'}} type="submit">Speichern</Button>
                </Form>
            </Box>
        </Dialog>
    )
}
