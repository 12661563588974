import {BASEURL} from "./Data";

export class Fetch {

    public static post(url: string, body: any) {
        return fetch(BASEURL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
    }

    public static get(url: string) {
        return fetch(BASEURL + url);
    }

}
