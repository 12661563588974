import {
    BaseStyles, Box
} from "@primer/react";
import {UnderlineNav} from '@primer/react/drafts'
import React from "react";
import {Link, Outlet, useMatch, useResolvedPath} from 'react-router-dom'

function UnderlineNavItem({to, children, ...rest}) {
    const resolved = useResolvedPath(to)
    const isCurrent = useMatch({path: resolved.pathname, end: true})
    return (
        <UnderlineNav.Item as={Link} to={to} aria-current={isCurrent ? 'page' : undefined} {...rest}>
            {children}
        </UnderlineNav.Item>
    )
}

export function Root() {
    return (
        <BaseStyles style={{width: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            <UnderlineNav aria-label="Repository" sx={{flexGrow: 0, bg: 'canvas.inset'}}>
                <UnderlineNavItem to="/plan">Essensplan</UnderlineNavItem>
                <UnderlineNavItem to="/">Rezepte</UnderlineNavItem>
                <UnderlineNavItem to="/produkte">Produkte</UnderlineNavItem>
            </UnderlineNav>
            <Box sx={{flexGrow: 1, bg: 'canvas.default'}}>
                <Outlet/>
            </Box>
        </BaseStyles>
    );
}
