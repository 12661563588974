import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter, createRoutesFromElements, Route,
    RouterProvider,
} from "react-router-dom";
import "./index.css";
import {Text, ThemeProvider} from "@primer/react";
import {Root} from "./Root";
import {Data} from "./Data";
import {Recipes} from "./Recipes";
import {Products} from "./Products";

void Data.init();

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root/>}>
            <Route element={<Text>Plan</Text>} path="plan" />
            <Route index element={<Recipes/>} />
            <Route path="produkte" element={<Products/>} />
        </Route>
    )
);

// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <ThemeProvider dayScheme="dark_dimmed" nightScheme="dark_dimmed">
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>
);
