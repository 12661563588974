import {
    Box,
    Heading, IconButton,
    Text,
    TextInput
} from "@primer/react";
import {PencilIcon, SearchIcon, PlusIcon} from '@primer/octicons-react';
import React from "react";
import {RecipeEditor, recipeToDisplayRecipe} from "./RecipeEditor";
import {Data, Recipe} from "./Data";

export function Recipes() {
    const [recipes, setRecipes] = React.useState(Data.getRecipes());
    Data.observeRecipes().subscribe((x) => setRecipes(x));

    const [filterVal, setFilterVal] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);

    const openDialog = React.useCallback(() => setIsOpen(true), [setIsOpen]);
    const closeDialog = React.useCallback(() => setIsOpen(false), [setIsOpen]);

    return (
        <>
            <Box sx={{width: '100%', display: 'flex', alignItems: 'center', px: 2, pt: 2, bg: 'canvas.default', justifyContent: 'end'}}>
                <Box sx={{py: 2}}>
                    <TextInput sx={{minWidth: 'min(400px, 30vw)'}}
                               value={filterVal}
                               onChange={(e) => setFilterVal(e.currentTarget.value)}
                               leadingVisual={SearchIcon}
                               aria-label="Suchen"
                               name="name"
                               placeholder="Suchen"
                               autoComplete="off"
                    />
                </Box>
                <IconButton aria-label="Neu" icon={PlusIcon} variant="primary" sx={{mx: 3}} onClick={openDialog}/>
            </Box>
            <Box sx={{flexGrow: 1, px: 4, py: 2}} bg="canvas.default">
                <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>{
                    recipes
                        .filter(recipe => recipe.name.toLowerCase().includes(filterVal.toLowerCase()))
                        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                        .map(recipe => (
                            <RecipeElement key={recipe.id} recipe={recipe}></RecipeElement>
                        ))
                }</Box>
            </Box>
            {isOpen && (
                <RecipeEditor recipe={{name:"", ingredients:[]}} close={closeDialog}></RecipeEditor>
            )}
        </>
    );
}

export function RecipeElement(args: {recipe: Recipe}) {
    const {recipe} = args;
    const [isOpen, setIsOpen] = React.useState(false);

    const displayRecipe = recipeToDisplayRecipe(recipe);

    return (
        <Box sx={{
            borderWidth: 1, borderStyle: 'solid', borderColor: 'border.default', px: 3, py: 2, borderRadius: 2,
            bg: "canvas.subtle", display: "flex", gap: 3, alignItems: "center"
        }}>
            <Heading sx={{fontSize: 16}}>{recipe.name}</Heading>
            <Text sx={{color: 'fg.muted', fontSize: 14}}>
                {displayRecipe.ingredients.map((ing) =>
                    `${ing.amount} ${ing.name}`).join(", ")}
            </Text>
            <Box sx={{marginLeft: "auto"}} onClick={() => setIsOpen(true)}>
                <PencilIcon size={20}></PencilIcon>
            </Box>
            {isOpen && (
                <RecipeEditor recipe={displayRecipe} close={() => setIsOpen(false)}></RecipeEditor>
            )}
        </Box>
    );
}
